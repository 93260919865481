import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';
import { Footer } from 'mappers/footer/models';
import { Header } from 'mappers/header/models';
import { ContentPage } from 'templates/ContentPage';

import { NotFoundItem, NotFoundQueryResult } from './models';

import './style.scss';

const NotFoundPage = () => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allContentstackHarpicNotFound,
    allContentstackHarpicHeader,
    allContentstackHarpicFooter,
  } = useStaticQuery<NotFoundQueryResult>(graphql`
    {
      allContentstackHarpicHeader {
        nodes {
          ...HeaderFragment
        }
      }
      allContentstackHarpicFooter {
        nodes {
          ...FooterFragment
        }
      }
      allContentstackHarpicNotFound {
        nodes {
          publish_details {
            locale
          }
          page_ref {
            uid
            fields {
              ...PageFieldsFragment
            }
            page_header {
              ...PageHeaderFragment
            }
            seo {
              ...SeoFragment
            }
            body {
              ...BodyFragment
            }
          }
        }
      }
    }
  `);

  const {
    publish_details: { locale },
    page_ref: [notFound],
  } = allContentstackHarpicNotFound.nodes.find(
    (item) => item.publish_details?.locale === currentLangCode
  ) as NotFoundItem;

  const header = allContentstackHarpicHeader.nodes.find(
    (item) => item.publish_details?.locale === currentLangCode
  );

  const footer = allContentstackHarpicFooter.nodes.find(
    (item) => item.publish_details?.locale === currentLangCode
  );

  return (
    <div className="not-found">
      <ContentPage
        data={{
          contentstackHarpicContentPage: notFound,
          contentstackHarpicHeader: header as Header,
          contentstackHarpicFooter: footer as Footer,
        }}
        pageContext={{
          locale,
          title: notFound.seo.title,
          uid: notFound.uid,
        }}
      />
    </div>
  );
};

export default NotFoundPage;
